<template>

    <v-row style="padding-right: -200px;padding-left: 12px;padding-top: 12px">
        <div class="dashboard">
            <Sidebar/>
            <div class="content">
                <router-view/>
            </div>
        </div>
    </v-row>

</template>

<script>
import Sidebar from '../components/Sidebar'
export default {
    name: 'home',

    components: {
        Sidebar
    }
}
</script>

<style>
.dashboard {
    display: grid;
    grid-template-columns: 1fr 5fr;
    /* background: #f0f5ff; */
    background: #dbe7ff;
    height: auto;
    width: auto;

}

.content {
    background: #f0f5ff;
    border-radius: 10px;
    margin: 6px 6px 6px 0px;
    padding-right: 70px;
    
}
</style>