import Vue from 'vue'
import VueRouter from 'vue-router'
import dashboard from '../views/Dashboard'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    component: dashboard,
    children: [
      {
        path: '/',
        component: () => import(/* webpackChunkName: "Overview" */ '../views/Product.vue')
      },
      {
        path: 'Product-Catalogue',
        component: () => import(/* webpackChunkName: "Messages" */ '../views/Catalogue.vue')
      },
      {
        path: 'api-integration',
        component: () => import(/* webpackChunkName: "Profile" */ '../views/Api.vue')
      },
      {
        path: 'woocommerce-integration',
        component: () => import(/* webpackChunkName: "Profile" */ '../views/Woocommerce.vue')
      },

    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
