<template>
  <div class="sidebar">
    <img
      style="
        margin-left: 20px;
        margin-top: 20px;
        background: transparent;
        max-width: 70%;
      "
      :src="require(`../assets/LCpay_logo.png`)"
    />

    <div class="menu-items">
      <router-link
        to="/"
        active-class="active"
        tag="button"
        exact
        class="side-btn"
      >
        <div class="link-container">Single Product</div>
      </router-link>

      <router-link
        to="/product-catalogue"
        active-class="active"
        tag="button"
        exact
        class="side-btn"
      >
        <div class="link-container">Product Catalogue</div>
      </router-link>

      <router-link
        to="/api-integration"
        active-class="active"
        tag="button"
        exact
        class="side-btn"
      >
        <div class="link-container">API Integration</div>
      </router-link>

      <router-link
        to="/woocommerce-integration"
        active-class="active"
        tag="button"
        exact
        class="side-btn"
      >
        <div class="link-container">WooCommerce Integration</div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.title {
  color: white;
  font-size: 24px;
  margin-top: 10px;
}

.menu-items {
  display: flex;
  flex-direction: column;
  margin-left: 6px;
}

.menu-items > * {
  margin-top: 60px;
}

.side-btn {
  border: none;
  padding: 16px 0px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: #6a6868;
  background-color: transparent;
}

.side-btn:focus {
  outline: none;
}

.side-btn.active {
  position: relative;
  background-color: #f0f5ff;
  color: #409eff;
  font-weight: 600;
  margin-left: 10px;
  border-radius: 30px 0 0 30px;
}

.side-btn.active::before {
  top: -30px;
}

.side-btn.active::after {
  bottom: -30px;
}

.side-btn.active::before,
.side-btn.active::after {
  position: absolute;
  content: "";
  right: 0;
  height: 30px;
  width: 30px;
  background-color: #f0f5ff;
}

.side-btn.active .link-container::before {
  top: -60px;
}

.side-btn.active .link-container::after {
  bottom: -60px;
  z-index: 99;
}

.side-btn.active .link-container::before,
.side-btn.active .link-container::after {
  position: absolute;
  content: "";
  right: 0px;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: #dbe7ff;
}
</style>